<template>
    <div class="all">
        <index-text-header></index-text-header>
        <div class="base-container" v-if="$route.path !== '/base'">
            <v-breadcrumb :index="index" :child="child"></v-breadcrumb>
            <div class="body">
                <div class="left">
                    <left-menu :index="index" :child="child"></left-menu>
                </div>
                <div class="right">
                    <router-view />
                </div>
            </div>
        </div>
        <error :show="false" v-else></error>
        <index-footer></index-footer>
    </div>
</template>

<script>
import IndexTextHeader from "../compontents/index/IndexTestHeader";
import IndexFooter from "../compontents/index/IndexFooter";
import LeftMenu from "../compontents/base/LeftMenu.vue";
import VBreadcrumb from "../compontents/base/VBreadcrumb.vue";
import Error from "../compontents/Error.vue";
import { MethodsUtil } from "../assets/util/method.js";
export default {
    components: {
        IndexTextHeader,
        IndexFooter,
        VBreadcrumb,
        Error,
        LeftMenu
    },
    data() {
        return {
            index: "",
            child: ""
        };
    },
    mounted() {
        let { index, child } = MethodsUtil.getRouteIndexAndChild(this.$route);
        this.index = index;
        this.child = child;
    },
    methods: {},
    watch: {
        $route(val) {
            let { index, child } = MethodsUtil.getRouteIndexAndChild(val);
            this.index = index;
            this.child = child;
        }
    }
};
</script>

<style lang="less" scoped>
.all {
    display: flex;
    flex-direction: column;
    min-height: 100vh;
    .base-container {
        flex: 1;
        .body {
            width: 1200px;
            margin: 46px auto 0;
            display: flex;
            align-items: flex-start;
            // justify-content: space-between;
        }
        .left {
            min-width: 100px;
            flex-shrink: 0;
        }
        .right {
            margin-left: 60px;
            flex: 1;
        }
    }
}
@media screen and (max-width: 960px) {
    .all {
        .base-container {
            .body {
                margin-top: 8vw;
                width: 100%;
                display: block;
                .left {
                    display: none;
                }
                .right {
                    margin-left: 0;
                    padding: 0 8vw;
                }
            }
        }
    }
}
</style>
