<template>
    <div class="menu" v-if="menu.length && menu[index].label">
        <div class="title">{{ menu[index].label | language }}</div>
        <div class="menu-list" v-if="menu[index].children.length">
            <div
                class="menu-item"
                v-for="(item, i) in menu[index].children"
                :key="item.src"
                :class="{ active: i == child, 'menu-item-en': isEn }"
                v-if="item.show"
                @click="goTo(item)"
            >
                {{ item.label | language }}
            </div>
        </div>
    </div>
</template>

<script>
import { menu } from "../../assets/util/menu.js";
export default {
    props: {
        index: { default: 0 },
        child: { default: 0 }
    },
    data() {
        return {
            menu: []
        };
    },
    computed: {
        localLanguage() {
            return this.$store.state.language;
        },
        isEn() {
            return this.localLanguage === "en";
        }
    },
    mounted() {
        this.menu = menu;
    },
    methods: {
        goTo(item) {
            this.$router.push(item.src);
        }
    }
};
</script>

<style lang="less" scoped>
.menu {
    // min-width: 100px;
    text-align: left;
    .title {
        height: 29px;
        font-size: 18px;
        font-weight: 600;
        opacity: 0.3;
        color: #000;
        line-height: 29px;
    }
    .menu-list {
        margin-top: 40px;
        .menu-item {
            height: 43px;
            // min-width: 100px;
            padding: 10px 0;
            font-size: 16px;
            user-select: none;
            color: #000;
            opacity: 0.6;
            border-bottom: 1px solid #d1d4da;
            white-space: nowrap;
            &:hover {
                color: #2c8dfc;
                cursor: pointer;
                opacity: 1;
            }
            &:last-child {
                border-bottom: none;
            }
        }
        .menu-item-en {
            font-size: 14px;
        }
        .active {
            color: #2c8dfc;
            opacity: 1;
        }
    }
}
</style>
